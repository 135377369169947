import $ from 'jquery';
import Cookies from 'js-cookie';

var votingArray = [];
var totalVotes = 0;

$(document).ready(function () {
    if ($('.c-voting').length > 0) {
        $('.c-voting--enabled').each(function () {
            var cookie = Cookies.get(
                'dsw-votings-' + $(this).attr('data-voting-id')
            );

            if (cookie === undefined) {
                showVoting(1, $(this).attr('data-voting-id'));
            } else {
                if (cookie > 0) {
                    showVoting(0, $(this).attr('data-voting-id'));
                } else {
                    showVoting(1, $(this).attr('data-voting-id'));
                }
            }
        });
    }
});

function updateDB(voteID, votingID) {
    var $url =
        $('#voting-' + votingID + ' .c-voting__items').data('votes-url') +
        voteID;

    $('#voting-' + votingID).removeClass('c-voting--enabled');

    // $('#voting-' + votingID).addClass('c-voting--loading');

    $.ajax({
        type: 'GET',
        url: $url,
        success: function (data) {
            // $('#voting-' + votingID).removeClass('c-voting--loading');

            votingArray = [];
            $.each(data, function (index, item) {
                votingArray[index] = parseInt(item);
            });

            updateCookie(voteID, votingID);

            handleResponse(votingID);
        },
        error: function () {
            $('#voting-' + votingID).addClass('c-voting--enabled');
        },
        dataType: 'json',
    });
}

function handleResponse(votingID) {
    // $('#voting-' + votingID).removeClass('c-voting--enabled');

    // reset
    totalVotes = 0;
    $('#voting-' + votingID + ' .c-voting__percentage-value').html(0);
    $('#voting-' + votingID + ' .c-voting__total-votes').html(0);

    //refill
    votingArray.forEach(function (item, index) {
        totalVotes = totalVotes + votingArray[index];
    });

    $('#voting-' + votingID + ' .c-voting__total-votes').text(totalVotes);

    votingArray.forEach(function (item, index) {
        var percentage = (votingArray[index] / totalVotes) * 100;
        percentage = Math.round(percentage);
        setTimeout(function () {
            $('#vote-' + index + ' .c-voting__progress-bar').attr(
                'style',
                'width:' + percentage + '%'
            );
        }, 100);
        $('#vote-' + index + ' .c-voting__percentage-value')
            .attr('data-count', percentage)
            .text(percentage);
    });
}

function updateCookie(voteID, votingID) {
    Cookies.set('dsw-votings-' + votingID, voteID, {
        expires: 365,
        path: '/',
    });
}

function showVoting(enableVoting, votingID) {
    if (enableVoting === 0) {
        $('#voting-' + votingID).removeClass('c-voting--enabled');

        // reset
        totalVotes = 0;
        votingArray = [];

        $('#voting-' + votingID + ' .c-voting__item').each(function () {
            votingArray[$(this).data('uid')] = $(this).data('votes');
            totalVotes = totalVotes + $(this).data('votes');
        });

        $('#voting-' + votingID + ' .c-voting__total-votes').text(totalVotes);

        /* set percentages depending on total vote count */
        votingArray.forEach(function (item, index) {
            var percentage = (votingArray[index] / totalVotes) * 100;
            percentage = Math.round(percentage);
            $('#vote-' + index + ' .c-voting__progress-bar').attr(
                'style',
                'width:' + percentage + '%'
            );
            $('#vote-' + index + ' .c-voting__percentage-value').text(
                percentage
            );
        });
    } else {
        $('.c-voting--enabled .c-voting__item').on('click', function (e) {
            e.preventDefault();

            updateDB(
                $(this).data('uid'),
                $(this).parent().parent().attr('data-voting-id')
            );

            /*
            updateCookie(
                $(this).data('uid'),
                $(this).parent().parent().attr('data-voting-id')
            );
            */
        });
    }
}
